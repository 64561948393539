import React from "react";
import { Accordion } from "@mantine/core";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import Section from "../../../components-v2/Base/Section";
import Heading from "../../../components-v2/Base/HeadingBuilder";
import LeadForm from "../../../components/Forms/LeadForm";
import CircleImage from "../../../components/ImageQuerys/CircleEntries";
import HeroImage from "../../../components/ImageQuerys/HeroImages";
import LargeFeatures from "../../../components/LargeFeatures/LargeFeatures";
import ImageLF from "../../../components/ImageQuerys/LargeFeaturesImages";
import data from "../../../data/forms/lead-form";
import {
  hero,
  speakers,
  largeFeatures1,
  heroWithList,
  largeFeatures2,
  accordion,
} from "../../../data/webinars/pointsofprofit";
import { WEBINARS_POINTS_OF_PROFIT } from "../../../utils/firebase/forms";
import ogImage from "../../../images/global_assets/og-image.png";
import blueDot from "../../../images/svg/vector-10.svg";
import check from "../../../images/svg/checkmark.svg";
import heroBgLeft from "../../../images/svg/vector-16.svg";
import CtaPrimary from "../../../components-v2/Base/CtaPrimary";

function Icon() {
  return (
    <svg
      className="plus-icon"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="vertical-line"
        d="M15 1V29"
        stroke="#090D12"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="horizontal-line"
        d="M1 15H29"
        stroke="#090D12"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function Page() {
  return (
    <Layout
      transparentHeader
      isLanding
      useNewLPHeader
      className="non-fixed-width qr-page"
    >
      <SEO
        title="Webinar | Path to Profit for New Restaurants | SpotOn"
        description="Register for our free webinar and discover how you can turn a profit faster when opening a new restaurant."
        image={`https://spoton.com/${ogImage}`}
      />

      <Section
        fullWidth
        className="bg-black pt-20 lg:pt-28 pb-12"
        animations={false}
      >
        <img
          alt="background"
          src={heroBgLeft}
          className="absolute left-0 top-24 opacity-0 lg:opacity-100"
        />
        <div className="grid grid-cols-1 lg:grid-cols-2 justify-between items-center md:gap-y-10 gap-x-16">
          <div className="max-w-[535px] w-full mx-auto lg:mx-0">
            <Heading
              className="text-white relative"
              level={1}
              injectionType={1}
              withBlueSymbol
            >
              {hero.title}
            </Heading>

            <p className="text-[24px] text-black-500 mt-6 relative">
              {hero.description}
            </p>
          </div>
          <div className="relative" id="form">
            <img
              className="absolute opacity-100 top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"
              src={blueDot}
              // align="get an enterprise demo background"
              alt="get an enterprise demo background"
            />
            <LeadForm
              leadType="webinar"
              dataLayerEventNameAppendix="-lead"
              formName="Points Of Profit Webinar Form"
              formData={data}
              thankYouPath="/webinars/pathtoprofit/thank-you"
              noHeader
              plain
              lineOfBusiness="Hospitality"
              collection={WEBINARS_POINTS_OF_PROFIT}
            />
          </div>
        </div>
      </Section>

      <Section fullWidth className="pt-10" animations={false}>
        <div className="grid grid-cols-1 lg:grid-cols-2 justify-between gap-8">
          <div className="text-black-200 text-p max-w-[560px] mx-auto lg:mx-0">
            {speakers.text}
          </div>
          <div className="flex gap-2 gap-y-8 xl:gap-8 flex-wrap lg:flex-nowrap justify-center lg:justify-end">
            {speakers.people.map((person) => (
              <div className="max-w-[135px] text-center" key={person.name}>
                <CircleImage
                  imageName={person.image}
                  alt="person avatar"
                  className="max-w-[112px]"
                />
                <div className="text-black text-p mt-2">{person.name}</div>
                <div className="text-black-200 text-[14px] mt-1.5">
                  {person.position}
                </div>
              </div>
            ))}
          </div>
        </div>
      </Section>

      <LargeFeatures className="mt-10 md:mt-20" sectionData={largeFeatures1} />

      <Section>
        <Heading
          className="text-center"
          level={2}
          injectionType={2}
          withBlueSymbol
          symbol=":"
        >
          {heroWithList.title}
        </Heading>

        <div className="bg-[#F3F4F6] rounded-[24px] p-4 md:p-10">
          <ul
            className={`
          mt-10
          mx-auto grid grid-cols-1 md:grid-cols-2
          pl-0 justify-between gap-y-10
          lg:gap-x-28 xl:gap-x-80
        `}
          >
            {heroWithList.items.map((item, idx) => (
              <li
                key={item}
                className={`flex flex-row items-start lg:max-w-[340px] xl:max-w-[460px] ${
                  idx % 2 > 0 ? "" : ""
                }`}
              >
                <img src={check} alt="checkbox" className="mr-8 mt-2" />
                <div>
                  <Heading
                    level={4}
                    injectionType={4}
                    noMargin
                    className="text-black"
                  >
                    {item}
                  </Heading>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </Section>

      <LargeFeatures className="mt-4" sectionData={largeFeatures2} />

      <Section>
        <div className="bg-[#F3F4F6] rounded-[24px] p-4 md:p-10 flex flex-col items-center">
          <Heading
            level={2}
            injectionType={3}
            withBlueSymbol
            className="text-center text-black"
          >
            Special offer included
          </Heading>

          <p className="text-black-200 text-[20px] text-center mx-auto">
            All attendees will receive a special offer towards the end of the
            webinar.
          </p>

          <CtaPrimary className="mt-6" title="Register now" target="#form" />

          <div className="w-full min-h-[50px] mt-10">
            <HeroImage imageName="pop-hero.png" />
          </div>
        </div>
      </Section>

      <Section>
        <Heading
          className="text-black text-center"
          level={2}
          injectionType={1}
          withBlueSymbol
        >
          {accordion.title}
        </Heading>

        <p className="text-black-200 text-[20px] text-center">
          {accordion.description}
        </p>

        <Accordion
          className="accordion-1 mt-16 2xl:mt-20"
          chevronPosition="right"
          disableChevronRotation
          // defaultValue="Michael Mina"
          multiple
        >
          {accordion.speakers.map((item) => (
            <Accordion.Item key={item.name} value={item.name}>
              <Accordion.Control chevron={Icon()} className="py-[30px]">
                <div className="">
                  <Heading
                    level={3}
                    injectionType={3}
                    withBlueSymbol
                    className=""
                    noMargin
                  >
                    {item.name}
                  </Heading>
                  <div className="text-black-200 text-p -mt-4">
                    {item.position}
                  </div>
                </div>
              </Accordion.Control>

              <Accordion.Panel className="text-black-200 text-p pb-4">
                <div className="flex flex-col lg:flex-row gap-8 items-center md:items-start">
                  <ImageLF
                    imageName={item.image}
                    className="md:max-w-[230px] min-w-[230px]"
                  />
                  <p className="text-p text-black-200 max-w-[770px]">
                    {item.text}
                  </p>
                </div>
              </Accordion.Panel>
            </Accordion.Item>
          ))}
        </Accordion>
      </Section>

      <div className="mb-20 mt-8">
        <CtaPrimary title="Register now" target="#form" />
      </div>

      <style jsx="true" global>{`
        .advisory-hero-image.gatsby-image-wrapper,
        .advisory-hero-image.gatsby-image-wrapper img {
          border-radius: 24px !important;
        }

        .accordion-1 button.mantine-Accordion-control,
        .accordion-1 .mantine-Accordion-content {
          padding-left: 0;
        }
        .accordion-1 button.mantine-Accordion-control:hover {
          background: none !important;
        }
        .accordion-1 svg path {
          transition: all 800ms;
          transform: rotate(0);
          transform-origin: 50% 50%;
        }

        .accordion-1
          .mantine-Accordion-item[data-active="true"]
          svg
          path.vertical-line {
          //transform: rotate(270deg);
          transform: rotate(450deg);
        }
      `}</style>
    </Layout>
  );
}

export default Page;
