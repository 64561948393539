import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const Image = (props) => {
  const data = useStaticQuery(graphql`
    query CircleEntries {
      allFile(filter: { relativeDirectory: { eq: "circle-entries" } }) {
        edges {
          node {
            base
            id
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  `);

  return (
    <>
      {data.allFile.edges.map(
        ({ node }, idx) =>
          node.base === props.imageName && (
            <GatsbyImage
              image={getImage(node)}
              key={idx.toString()}
              className={props.className}
              style={props.style}
              alt="image"
            />
          )
      )}
    </>
  );
};

Image.propTypes = {
  imageName: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.string),
};
Image.defaultProps = {
  imageName: '',
  className: '',
  style: {},
};

export default Image;
