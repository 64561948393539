export const hero = {
  title: `Free webinar: The Path to Profit for New Restaurants`,
  description:
    "Tools, tips, and expert advice to jumpstart restaurant profitability.",
};

export const speakers = {
  text: "If you are a new or upcoming restaurant owner, this is the perfect opportunity to kickstart your business the right way. Join us on March 20, 2023, at 1:00 PM PST for exclusive insights on how to run a profitable restaurant. With more than 50 years of combined restaurant operations experience, Hillary Holmes, Chip Klose, and Benson Wang will be offering their expertise to help you maximize profits.",
  people: [
    {
      name: "Hillary Holmes",
      position: "SpotOn Operator in Residence",
      image: "hillary-holmes.png",
    },
    {
      name: "Benson Wang",
      position: "Palm Hospitality Group Co-founder",
      image: "benson-wang.png",
    },
    {
      name: "Chip Klose",
      position: "Restaurant Coach",
      image: "chip-klose.png",
    },
  ],
};

export const largeFeatures1 = {
  sectionTitle: "",
  featureBlocks: [
    {
      blockTitle: "Turn more tables & more profit",
      blockSubTitle:
        "Learn how to utilize the right tools for quality reporting, labor management, reservations & waitlisting, and online ordering. \n Reach the break even-point even faster! We know building a successful restaurant is a known challenge with the majority of restaurants failing in the first year. Let us raise your expectations. In this webinar, we will show you how small changes, careful planning, and the right technology will accelerate profitability for your new restaurant.",
      blockImg: "pop-webinar-1.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Sign me up",
        ctaTarget: "#form",
      },
    },
  ],
};

export const heroWithList = {
  title: "In this webinar we will cover",
  image: "pop-hero.png",
  items: [
    "Diversifying revenue with online ordering",
    "Comprehensive reporting",
    "Integrating loyalty with point-of-sale discounts & sales",
    "Streamlining operations & employee management",
    "Building a customer database",
    "Turning tables with less staff",
  ],
};

export const largeFeatures2 = {
  sectionTitle: "",
  featureBlocks: [
    // {
    //   blockTitle: "Special offer included",
    //   blockSubTitle:
    //     "All attendees will receive a special offer towards the end of the webinar.",
    //   blockImg: "pop-webinar-2.png",
    //   forceReverse: false,
    //   ctaPrime: {
    //     ctaTitle: "Sign me up",
    //     ctaTarget: "#form",
    //   },
    // },
    {
      blockTitle: "Make time to save time",
      blockSubTitle:
        "This 1 hour webinar could save you 650 work hours in 1 year. Lino Suazo, GM of Bungalow Kitchen reported that SpotOn saved him “nearly 2.5 hours most work days.” 2.5 daily hours x 260 average yearly work days = 650 annual hours saved.",
      blockImg: "pop-webinar-3.png",
      forceReverse: true,
      ctaPrime: {
        ctaTitle: "Let's save time",
        ctaTarget: "#form",
      },
    },
  ],
};

export const accordion = {
  title: "Meet our speakers",
  description: "Bringing together decades of combined restaurant experience.",
  speakers: [
    {
      name: "Hillary Holmes",
      position: "SpotOn Operator in Residence",
      image: "hillary-holmes-new.png",
      text: "With nearly two decades of hospitality experience, Hillary Holmes leads strategic partnerships at SpotOn, where she focuses on the best integrations and partnerships to provide top of the line offerings to those we serve in the hospitality industry. After 18 years in restaurants, Holmes made the decision to jump from restaurant operations to restaurant technology so that she could expand her impact on those working in this field.",
    },
    {
      name: "Benson Wang",
      position: "Palm Hospitality GroupCo-founder",
      image: "benson-wang.png",
      text: "Born into a family of third generation restaurateurs and distillers, Benson opened The Dorian and then founded the parent company, PHH in 2014. As co-founder, Benson oversees day-to-day operations, recruitment, financial planning and reporting, purchasing, concept development and best business practices to deliver transportive leisure. Benson is a board member of the Golden Gate Restaurant Association and an SF Travel committee member.",
    },
    {
      name: "Chip Klose",
      position: "Restaurant Coach",
      image: "chip-klose.png",
      text: "Chip Klose is a Restaurant Coach and Host of the Restaurant Strategy Podcast. With more than 20 years of industry experience, he works with chefs, owners, and operators all over the world to help them build more profitable (and more sustainable) businesses. He's a contributing writer for Bar & Restaurant, a noted public speaker, and the author of the upcoming book, Restaurant Strategy.",
    },
  ],
};
